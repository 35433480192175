import { onMounted, computed } from 'vue'
import { useMapState, useMapMutations, useMapActions } from 'public/src/pages/checkout/hooks/index.js'
import { template } from '@shein/common-function'

export const useQuickShipInit = () => {
  const { checkout, quickShipInfoTag, qsMallList } = useMapState([ 'checkout', 'quickShipInfoTag', 'storeData', 'buyNowNo' , 'qsMallList'])
  const { assignState } = useMapMutations(['assignState'])
  const { newQuickShip } = useMapActions(['newQuickShip'])

  const quickShip = async () => {
    const { OrderQuickshipStyle } = checkout.value.abtInfo || {}
    const orderQuickshipStyleStatus = OrderQuickshipStyle?.param?.OrderQuickshipStyleStatus == 1
    if(!quickShipInfoTag.value?.qsDataProps && !quickShipInfoTag.value?.qsDataNoLabelProps){
      await newQuickShip()
      const { last_quickship_tag  } = qsMallList.value
      const qsDataProps = {
        isShowNewStyle: orderQuickshipStyleStatus, // 是否展示新样式，即带有icon + x 日达 | 老样式
        text: last_quickship_tag || '', // qs标签标题（前半部分内容）
        noLableStyle: false, // 是否需要lable标签样式
        // qs标签时效信息（后半部分内容）1.新样式 2. isLocationTop = false 3.tip有值
        qsTimelinessInfo: {
          isLocationTop: true,
          // tip: result?.info
        }
      }
      const qsDataNoLabelProps = { ...qsDataProps, noLableStyle: true }
      assignState({ 
        quickShipInfoTag: {
          qsDataProps,
          qsDataNoLabelProps,
        }, 
      })
    }
  }
  onMounted(() => {
    typeof window != 'undefined' && quickShip('checkout')
    // typeof window != 'undefined' && newQuickShip('checkout')
    // typeof window != 'undefined' && newQuickShip('carts')
  })
  return {}
}

export const useGetGuickShip = () => {
  const { quickShipInfoTag, qsMallList, descQuickShipTime, checkout, language } = useMapState([ 'quickShipInfoTag', 'qsMallList', 'descQuickShipTime', 'checkout', 'language' ])
  const { qs_time_same, last_quickship_tag, allSceneQuickshipWordStatus, combine_transport_time_desc_replace  } = qsMallList.value
  // 对部分qs时效提示进行内容组装
  const qsReplenishDes = computed(() => {
    const qsReplenishDesAbt = checkout.value.abtInfo?.TradeTransStoreTest?.param?.TradeTransStoreTestStatus || 0
    let result = ''
    if(qs_time_same == 0){
      result = qsReplenishDesAbt == 0 
        ? template(last_quickship_tag, language.value.SHEIN_KEY_PWA_34722) 
        : qsReplenishDesAbt == 2 ? combine_transport_time_desc_replace : descQuickShipTime.value
    } else {
      result = allSceneQuickshipWordStatus == 'on' ? combine_transport_time_desc_replace || descQuickShipTime.value || '' : descQuickShipTime.value || ''
    }
    
    return last_quickship_tag ? result : ''
  })

  const Need_Qs_Freight = () => computed(() => {
    return [ 108 ].includes(+checkout.value?.default_address?.countryId)
  })
  
  return { quickShipInfoTag, qsMallList, qsReplenishDes, Need_Qs_Freight }
}
